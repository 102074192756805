import { isBrowser } from "@fwa/src/utils/browserUtils";
import { isDev, isTest } from "@fwa/src/utils/envUtils";

export const initUserzoom = () => {
  if (!isBrowser || isDev || isTest) return;
  const uz = document.createElement("script");
  uz.type = "text/javascript";
  uz.async = true;
  uz.charset = "utf-8";
  uz.src =
    "https://cdn4.userzoom.com/files/js/QzEyNDJUMSAg.js?t=uz_til&cuid=2674F36E0FCEE411B0190022196C2B88";
  const s = document.getElementsByTagName("script")[0];
  if (s.parentNode) {
    s.parentNode.insertBefore(uz, s);
  }
};
export default initUserzoom;
