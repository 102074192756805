import { datadogRum } from "@datadog/browser-rum";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { isDev, isTest } from "@fwa/src/utils/envUtils";

// this isn't a security risk because its the root package json not this packages so doesn't real dependency versions
// eslint-disable-next-line import/no-relative-packages
import pkg from "../../../../package.json";

const ENV_NAME = process.env.NEXT_PUBLIC_ENV_NAME || "";
const FWS_BASE_URL = process.env.NEXT_PUBLIC_FWS_BASE_URL || "";

const getHostname = (): string => {
  try {
    const url = new URL(FWS_BASE_URL);
    const { hostname } = url;
    return hostname;
  } catch {
    return "";
  }
};

export const setUpDataDog = () => {
  if (isDev || isTest) return;

  datadogRum.init({
    applicationId: "bb386083-b4af-4d13-aa40-f09e337fd328",
    clientToken: "pub35186d9874e5d1afd26bf0e8b2cc33c8",
    site: "datadoghq.eu",
    service: "online-fundraising",
    env: ENV_NAME,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: pkg.version,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    traceSampleRate: 20,
    allowedTracingUrls: [
      (url) => !!url.length && url.startsWith(getHostname()),
    ],
  });
};

export const trackDataDogError = (err: Error, extraInfo?: object) => {
  if (!isBrowser) return;
  if (isDev || isTest) return;
  datadogRum.addError(err, extraInfo);
};
